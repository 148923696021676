.center {
  text-align: -webkit-center !important;
}
.for_rl{
      font-size: larger;
    color: #dc1b5e;
    cursor: pointer;

}
.collapseddd {
 display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; 
  overflow: hidden;
  display: box;
  box-orient: vertical;
  line-clamp: 3;
}

.slct {
  margin-bottom: 10px;
  width: 20% !important;
  cursor: pointer;
}

.engage-title {
  width: 149px;
  height: 34px;
  flex-shrink: 0;
  fill: #000;
  position: relative;
  bottom: 30px;
  left: 0px;
  background-color: #fff;
  border-radius: 0px 48px 0px 0px;
  padding: 6px 10px 2px 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 1);
}

.for_linkstext {
  text-decoration: none;
}

.for_lefttext {
  font-size: 42px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}

.for_lefttextp {
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: justify;
  color: rgba(92, 92, 92, 1);
}

.for_secondtext2 {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 29px;
  color: white;
  background: #dc1b5e;
  /* display: block; */
  padding: 0px 4px;
  font-family: "Inter";
}

.LabelName {
  position: absolute;
  top: 25%;
  rotate: 41deg;
  right: 10%;
  white-space: nowrap;
}

.for_Adventuretype {
  border-color: #ffa923 !important;
   cursor: pointer;
}

.for-sold-out2 {
  position: absolute;
  top: 0%;
  color: white;
  right: 0%;
  opacity: 1;
  border-radius: 0 5px 5px 0px;
}

.undercard {
  padding: 15px 15px 0 15px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: rgba(92, 92, 92, 1);
}

.for_border {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
}

.for_location {
  padding: 14px 15px 25px 15px;
}

.undercard2 {
  padding: 15px 15px 0 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: rgba(92, 92, 92, 1);
}

.perfecttrip {
  font-size: 16px;
  padding-bottom: 0%;
}

.for_maindiv {
  margin-top: 40%;
}

.for_main_div {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);

  border-radius: 10px;
}

.placeholderclass {
  border-radius: 12px;
  border: 1.5px solid rgba(92, 92, 92, 1) !important;
  background-color: white !important;
}

.for_btn {
  padding: 7px 59px 7px 59px;

  border: none;
  border-radius: 22px;
  color: rgba(255, 255, 255, 1);
  border: rgba(255, 169, 35, 1);
  box-shadow: 4px -2px 4px 0px rgba(0, 0, 0, 0.25) inset;
  text-align: center;
}

.for_maldivtext {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  padding: 0 20px 24px 20px;
  color: rgba(92, 92, 92, 1);
}

.featuresubmit {
  text-align: center;
}

.engage {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}

.for_hr {
  border: 3px solid rgba(255, 169, 35, 1);
  background: rgba(255, 169, 35, 1);
  opacity: 1;
  position: relative;
  display: inline-block;
  width: 100%;
}

.for_hr::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: red;
}

.dayandnight {
  position: absolute;
  right: 4%;
  bottom: 35%;
  background: #000000cc;
  color: #ffffff;
  padding: 4px 12px 4px 11px;
  border-radius: 5px;
  text-transform: capitalize;
}

.undercard_international {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}

.undercard_international::before {
  content: "";
  position: absolute;
  opacity: 1;
  background: black;
  width: 2px;
  height: 32px;
  margin-left: -15px;
  top: 26px;
  background: rgba(255, 169, 35, 1);
}

.undercard_international::after {
  content: "";
  position: absolute;
  opacity: 1;
  background: black;
  width: 2px;
  height: 32px;
  margin-left: 13px;
  top: 26px;
  background: rgba(255, 169, 35, 1);
}

.for_cardexisttext {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: justified;
  text-align: justify;
  padding: 40px;
}

.form-container {
  display: block;
}

.for_card_featured {
  height: 370px !important;
  width: 350px !important;
  object-fit: cover !important;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 10px 1px #0000004d;
  position: relative;
}

.featured_img {
  height: 255px !important;
  /* width:268px !important;  */
  object-fit: cover !important;
  border-radius: 10px 10px 0px 0px;
}

.for_cardborder {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 25px;
}

.undercardbest {
  padding: 45px;
}

.for_about {
  position: relative;
  display: inline-block;
}

.for_featuredimg {
  position: relative;
  width: 100%;
  /* object-position: center; */
  object-fit: cover;
  height: 87vh;
  background-color: #000000;
}

.for_featured_under {
  position: absolute;
  top: 20%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.loremforfeatured {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  cursor: pointer;
  text-align: left;
  color: #000000;
}

@media (max-width: 768px) {
  .for_maindiv {
    margin-top: 40%;
  }

  .center {
    text-align: -webkit-center !important;
  }

  .slct {
    margin-bottom: 10px;
    width: 40% !important;
  }

  .placeholderclass {
    margin-bottom: 4%;
  }

  .for_featured_under {
    position: absolute;
    top: 16%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 1200px) {
  .for_featuredimg {
    height: 350px !important;
  }
}

@media (max-width: 992px) {
  .for_featuredimg {
    height: 350px !important;
  }

  .for_secondtext2 {
    font-size: 20px;
    font-weight: 500;
  }
}

@media (max-width: 576px) {
  .for_featuredimg {
    height: 250px !important;
  }

  .slct {
    margin-bottom: 10px;
    width: 50% !important;
  }

  .for_secondtext2 {
    color: white;
    /* padding: 5px  !important; */
    font-size: 15px !important;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
  }

  .center {
    text-align: -webkit-center !important;
  }
}

@media (max-width: 600px) {
  .form-container {
    display: none;
  }

  .accordion-container {
    display: block;
  }
}

@media (min-width: 601px) {
  .accordion-container {
    display: none;
  }
}

@media (max-width: 480px) {
  .for_maindiv {
    margin-top: 40%;
  }

  .for-mobile-enquriy-form {
    margin-top: 32px !important;
  }

  .center {
    text-align: -webkit-center !important;
  }

  .slct {
    margin-bottom: 10px;
    width: 70% !important;
  }

  .for_main_div {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25) !important;
    border-radius: 21px !important;
    margin-top: 9%;
    padding: 1px 50px;
  }

  .formobieview {
    margin-top: 1% !important;
  }

  .placeholderclass {
    margin-bottom: 4%;
    border: 2.1px solid black;
  }

  .for_featured_under {
    position: relative;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    margin-top: -140px;
    padding-bottom: 50px;
  }

  .for_secondtext2 {
    color: white;
    padding: 5px !important;
    font-size: 12px !important;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    text-align: left;
  }

  .perfecttrip {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .smalldevicerow {
    margin-top: 1% !important;
  }

  input:focus {
    outline: none;
  }

  .for_smalldevice {
    padding-top: 1% !important;
  }

  .undercardbest {
    padding: 8px 16px;
    text-align: justify;
  }

  .undercard_international {
    font-size: 18px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    cursor: pointer n    f ,;
  }

  .for_mobilefeatured {
    margin-top: 2% !important;
    
  }
}

.fetured_para {
  color: rgba(80, 80, 80, 1);
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.03em;
  cursor: pointer;
  text-align: left;
}

.main_para {
  display: flex;
  flex-direction: column;
}

.finalWidthfeatured {
  background: white !important;
  position: relative;

  margin-top: -56px;

  padding: 0 !important;
}

.hr_featured {
  border-top: 3px solid rgba(255, 169, 35, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin: 0px 10px; */
}

@media (max-width: 480px) {
  .dayandnight {
    position: absolute;
    right: 4%;
    bottom: 32% !important;
  }
}

@media (max-width: 320px) {
  .dayandnight {
    position: absolute;
    right: 4%;
    bottom: 41% !important;
    background: #000000cc;
    color: #ffffff;
    padding: 5px 7px 5px 9px;
    border-radius: 5px;
  }

  .perfecttrip {
    font-size: 14px;
    font-weight: 600;
  }

  .slct {
    margin-bottom: 10px;
    width: 70% !important;
  }

  .for_main_div {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25) !important;
    border-radius: 21px !important;
    margin-top: 9%;
    padding: 1px 14px;
  }
  .center-btn {
    display: flex;
    justify-content: center;
  }
  .for_secondtext2 {
    color: white;
    padding: 6px !important;
  }

  .sunclass {
    margin-top: 5% !important;
  }

  .for_featured_under {
    position: relative;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    margin-top: -140px;
    padding-bottom: 50px;
  }
}

.readmore {
  cursor: pointer;
  font-weight: 700;
  color: #dc1b5e;
}

.finalWidthfeature {
  width: fit-content;
  background: white !important;
  position: relative;
  margin-top: -56px;
  padding: 0 !important;
  border-radius: 20px;
}

.featuredlink {
  text-decoration: none;
}
